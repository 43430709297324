<template>
  <div class="text-lg">
    <span class="ml-1 text-gray-800">
      {{ $t('user.resultList.validFrom') }}
      {{ formattedValidFromDate }}
    </span>
    <span v-if="document.validTo" class="text-gray-800">
      {{ $t('user.resultList.validTo') }}
      {{ formattedValidToDate }}
    </span>
    <span v-if="creatorsName || typesOfLawName"> | </span>
    <span v-if="creatorsName" class="text-gray-800">
      {{ creatorsName }}
    </span>
    <span v-if="creatorsName && typesOfLawName" class="text-gray-800"> | </span>
    <span v-if="typesOfLawName" class="text-gray-800">
      {{ typesOfLawName }}
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { formatDate } from '@/helpers/DateFormatters';

export default {
  name: 'DocumentInformation',
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('Block', ['creators', 'typesOfLaw']),
    creatorsName() {
      return (
        // Return creator name or empty string as default
        this.creators.find(
          (o) => o.id === (this.document.creator || this.document.creatorId)
        )?.['name'] || ''
      );
    },
    typesOfLawName() {
      if (this.document.typeOfLaw?.name) {
        return this.document.typeOfLaw?.name;
      }

      return (
        // Return type of law name or empty string as default
        this.typesOfLaw.find(
          (o) => o.id === (this.document.typeOfLaw || this.document.typeOfLawId)
        )?.['name'] || ''
      );
    },
    formattedValidFromDate() {
      return formatDate(this.document.validFrom);
    },
    formattedValidToDate() {
      return formatDate(this.document.validTo);
    },
  },
};
</script>

<style scoped></style>
