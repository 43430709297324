<template>
  <div class="mx-auto my-10 responsive-container">
    <favorite-tabs
      class="mx-10 mt-16 responsive-container 4xl:mx-auto"
      :favorites="favorites"
      :active-language="currentLanguage"
      @changeLanguageTab="setCurrentLanguage"
    />
    <div class="max-w-[1360px] mx-10 4xl:mx-0 bg-white px-24 py-14 shadow-md">
      <h2 class="mb-16 text-4xl font-sansbold">{{ $t('favorite.title') }}</h2>
      <div>
        <table v-if="currentLanguageFavorites.length > 0">
          <tr>
            <th>{{ $t('favorite.table.title') }}</th>
            <th class="w-[150px]">{{ $t('favorite.table.date') }}</th>
            <th class="w-[40px]"></th>
          </tr>
          <favorite-list-entry
            v-for="favorite in currentLanguageFavorites"
            :key="favorite.id"
            :favorite="favorite"
            :current-language="currentLanguage"
          />
        </table>
        <p v-else class="text-base">
          {{ $t('favorite.empty') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteListEntry from '@/components/user/FavoriteListEntry';
import { mapState } from 'vuex';
import FavoriteTabs from '@/components/user/FavoriteTabs.vue';

export default {
  components: {
    FavoriteListEntry,
    FavoriteTabs,
  },
  data() {
    return {
      currentLanguage: this.$i18n.locale,
    };
  },
  computed: {
    ...mapState('Favorite', ['favorites']),
    currentLanguageFavorites() {
      return this.favorites[this.currentLanguage];
    },
  },
  mounted() {
    this.$store.dispatch('Favorite/loadFavorites');
  },
  methods: {
    setCurrentLanguage(lang) {
      this.currentLanguage = lang;
    },
  },
};
</script>
