<template>
  <tr>
    <td v-if="favorite.document" class="pr-">
      <router-link
        :to="getDocumentRoute(favorite.document.id)"
        class="text-lg no-underline font-sansbold"
      >
        {{ favorite.document.title }}
      </router-link>
      <div class="flex">
        <span class="text-lg text-gray-800">
          <search-document-state :document="favorite.document" /> |
        </span>
        <document-information :document="favorite.document" />
      </div>
    </td>
    <td v-if="favorite.lexBlock" class="pr-24">
      <router-link
        :to="getDocumentRoute(favorite.lexBlock.document.id)"
        class="text-lg no-underline font-sansbold"
      >
        {{ favorite.lexBlock.document.title }}
      </router-link>
      <div class="flex">
        <span class="text-lg text-gray-800">
          <search-document-state :state="favorite.lexBlock.document.state" /> |
        </span>
        <document-information :document="favorite.lexBlock.document" />
      </div>

      <router-link
        :to="getBlockRoute(favorite.lexBlock.document.id, favorite.lexBlock.id)"
        class="text-lg no-underline"
      >
        {{ favorite.lexBlock.text }}
      </router-link>
    </td>
    <td>
      <span>{{ saveDate }}</span>
    </td>
    <td>
      <button class="pl-4 text-secondary-600" @click="removeFavorite">
        <svg-icon name="delete" />
      </button>
    </td>
  </tr>
</template>

<script>
import { deleteFavorite } from '@/services/Favorite.service';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import SearchDocumentState from '@/components/SearchDocumentState.vue';
import DocumentInformation from '@/components/user/searchResult/DocumentInformation.vue';

export default {
  components: {
    SearchDocumentState,
    DocumentInformation,
  },
  props: {
    favorite: {
      type: Object,
      required: true,
    },
    currentLanguage: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('Block', ['creators', 'typesOfLaw']),
    title() {
      if (this.favorite.document) {
        return this.favorite.document;
      }
      return this.favorite.lexBlock;
    },
    saveDate() {
      const dateFormat = 'DD.MM.YYYY';
      return dayjs(this.favorite.saveDate).format(dateFormat);
    },
    creatorName() {
      let creatorId = this.favorite.document
        ? this.favorite.document.creator
        : this.favorite.lexBlock.document.creator;
      return this.creators.find((o) => o.id === creatorId)?.['name'] || '';
    },
    typesOfLawName() {
      let typeOfLawId = this.favorite.document
        ? this.favorite.document.typeOfLaw
        : this.favorite.lexBlock.document.typeOfLaw;
      return this.typesOfLaw.find((t) => t.id === typeOfLawId)?.['name'] || '';
    },
  },
  methods: {
    async removeFavorite() {
      const confirmMessage = {
        title: this.$t('user.favorites.deleteFavoriteConfirmation.title'),
        body: {
          note: this.$t('user.favorites.deleteFavoriteConfirmation.body'),
        },
      };
      const options = {
        okText: this.$t('user.favorites.deleteFavoriteConfirmation.yes'),
        cancelText: this.$t('user.favorites.deleteFavoriteConfirmation.no'),
      };
      this.$dialog.confirm(confirmMessage, options).then(async () => {
        await deleteFavorite(this.favorite.id);
        await this.$store.dispatch('Favorite/loadFavorites');
      });
    },
    getDocumentRoute(documentId) {
      return {
        name: 'documentDetail',
        params: { id: documentId },
        query: { lang: this.currentLanguage, loadNewest: true },
      };
    },
    getBlockRoute(documentId, blockId) {
      return {
        name: 'documentDetail',
        params: {
          id: documentId,
          blockId: blockId,
        },
        query: { lang: this.currentLanguage },
      };
    },
  },
};
</script>
