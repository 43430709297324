<template>
  <span>
    {{ validity }}
  </span>
</template>

<script>
export default {
  name: 'DocumentState',
  props: {
    state: {
      type: String,
      required: true,
    },
  },
  computed: {
    validity() {
      if (this.state === 'is_not_yet_in_order') {
        return this.$t('documentState.notYetInOrder');
      }
      if (this.state === 'is_out_of_order') {
        return this.$t('documentState.isOutOfOrder');
      }
      return this.$t('documentState.inOrder');
    },
  },
};
</script>
