<template>
  <document-layout class="frontend">
    <favorite-list></favorite-list>
  </document-layout>
</template>
<script>
import FavoriteList from '@/components/user/FavoriteList';

export default {
  components: { FavoriteList },
  mounted() {
    document.body.classList.add('frontend');
    document.body.classList.remove('backend');
  },
};
</script>
